@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.swiper {
  width: 100%;
  padding-bottom: 30px;
}

.swiper-pagination-bullet {
  background: white !important;
  height: 10px !important;
  width: 10px !important;
}

.swiper-pagination-bullet-active {
  background-color: #00889C !important;
}

.slides {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat;
  height: 380px;
}

.slide1 {
  background: url('../public/img/car1.jpg');
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.slide2 {
  background: url('../public/img/car2.jpg');
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.slide3 {
  background: url('../public/img/car3.jpg');
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

nav .dropdown-toggle i {
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 5px 6px;
  border-radius: 50%;
}

.navbar .dropdown-toggle {
  display: flex;
  align-items: center;
}

body {
  background-color: #f3f6f8;
  overflow: none;
  overflow-y: scroll !important;
}

nav .dropdown-toggle::after {
  display: none !important;
}

.navbar-brand {
  color: #00889C;
}

.navbar-brand h3 {
  color: #00889C;
}

.navbar-brand .fa-car {
  font-size: 25px;
}

.dropdown-menu[data-bs-popper] {
  left: -40px !important;
  top: 135%;
}

body>nav>div>div.nav-item.dropdown {
  margin-left: 25px;
}

.navbar-nav .nav-item {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.navbar-nav .nav-item>a {
  color: rgb(78, 77, 77);
}




.main_banner {
  background: url(../public/img/bg_1.jpg);
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 550px;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100% !important;
  background-attachment: fixed;
}

.main_banner1 {
  background: url(../public/img/bg_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100% !important;
  background-attachment: fixed;
}

.main_banner1 .inner_detail {
  margin-top: 0;
  width: 50% !important;
  left: 10% !important;
  margin-left: 50px;
}

.main_banner2 {
  background: url(../public/img/bg_2.jpg) !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.inner_detail {
  margin-top: -100px;

}

.new_page .inner_detail {
  margin-top: 0 !important;
  position: absolute;
  top: 14%;
  width: 48%;
  margin-left: 50px;

}

.inner_detail .card:nth-child(1) {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.search_area .border input {
  width: 98%;
  border: 1px solid #ccc;
  outline: 0;
  padding-left: 35px;
  border-radius: 8px;
  background-color: transparent;
  margin-right: 5px;
  font-size: 16px !important;


}

.search_area .border i {
  position: absolute;
  left: 13px;
  top: 16px;
  color: #ccc;
}

.search_area .border {

  border: none !important;
}

.search_area .border img {
  width: 35px;
  transform: rotate(90deg);
  padding: 3px;
}

.select_btn li {
  list-style: none;
  width: 100%;
  text-align: center;
  color: #00889C;
  padding-top: 8px;
  height: 40px;
  font-size: 15px;

}


.btn-outline-primary {
  color: #00889C !important;
  background-color: #fff !important;
  border-color: #00889C !important;
  border: 2px solid #00889C;
}

.btn-primary {
  background-color: #00889C !important;
  color: #fff !important;
}

.new_btn {
  background-color: #6AC882 !important;
  border: 0;
}

.search_area .btn-primary:hover,
.search_area .btn-primary.focus,
.search_area .btn-primary.active {
  background-color: #04798b !important;
  color: #fff !important;
}

.search_area .select_btn button {
  height: 40px;
  border: 0;
  font-size: 15px;
}

.search_area .select_btn {
  border: 1px solid #00889C;
  border-radius: 12px;
}

.fil-active {
  background-color: #00889C;
  color: white !important;
  height: 40px;
}

.className-active {
  color: #00889C !important;
  font-weight: 600;
}

.search_area .card:nth-child(1) {
  border: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.search_area .select_btn li:nth-child(1) {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.search_area .select_btn li:nth-child(2) {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.search_area input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  outline: 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: gray;
  opacity: .6;
}

.search_area select {
  border: 1px solid #ccc;
  font-size: 15px;
  height: 40px;
  border-radius: 8px;
  color: gray;
  opacity: .6;

}

.search_area select:focus {
  box-shadow: none !important;
  border: 1px solid #ccc;
}

.adv_search .btn,
.contact_details .btn {
  border-radius: 50px !important;
  padding: 7px 25px;
}


.how_use {
  background: linear-gradient(#1C777E, #166870);
  color: white;
}

.how_use i {
  color: white;
  font-size: 25px;
}

.how_use h5 {
  color: white;
}

.how_use p {
  font-size: 15px;
}

#f3f6f8

/*---login------------*/
.login .btn-primary {
  border-radius: 50px !important;
  height: 50px;
}

.other_media span {
  position: relative;
}

.other_media span::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 1px;
  background-color: black;
  left: -110px;
  bottom: 12px;

}

.other_media span::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 1px;
  background-color: black;
  right: -110px;
  bottom: 12px;
}

.login .applebtn {
  height: 40px;
  width: 40px;
  border: 2px solid white;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
}

.login .applebtn img {
  height: 100% !important;
  width: 100% !important;
  padding: 0px !important;
}

/*------contact-------------*/
.contact_details form input {
  height: 40px;
  outline: 0;
  padding: 0 15px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 4px;
}

.contact_details label {
  color: #000000;
}

.contact_details textarea {
  outline: 0;
  padding: 0 15px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 4px;

}

/*------------footer---------*/
footer {
  background-color: #fff;
}

.all_media_icons li img {
  height: 25px;
}

.all_media_icons li {
  list-style: none;
  margin: 5px 10px;
}

.all_media_icons li i {
  font-size: 25px;
  background-color: white;
  color: rgb(123, 123, 123);
  padding: 9px 10px;
  border-radius: 50%;
}

.all_media_icons li i:hover {
  background-color: #00889C;
  color: white;
}

li {
  list-style: none;
}

footer a {
  color: black;
  text-decoration: underline;
  position: relative;
}

footer .policies a:hover {
  color: #00889C;
  letter-spacing: 1px;
  transition: all .3s;
}


#root>section.login.container.d-flex.flex-column.justify-content-center.align-items-center.py-4.py-md-5>div>div>div {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;

}

footer .border {
  border: 2px solid white;
}

footer .border input {
  border: 0;
  outline: 0;
  height: 45px;
  padding: 0 10px;
  background-color: transparent;
  width: 80%;
  color: white;
}

footer .border input::placeholder {
  color: white;
}

footer .border button {
  background-color: #00889C !important;
  border: 0;
  outline: 0;
  height: 45px;
  width: 20%;
}

.lg-react-element a {
  width: 100% !important;
}


/*-----All-listings---------*/

.all_listings .card {
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.all_listings .card img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.all_listings .card h5 {
  font-weight: 700;
}

.all_listings .card .price {
  color: #089fb6;
}

.all_listings .card .bi {
  color: #089fb6;
  font-size: 20px;
  font-weight: 900;
}

a {
  text-decoration: none;
}

.blue {
  color: #0aa5bd;
}

.detail_card .fa-star {
  color: orange;
}

.detail_card img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.grey {
  color: gray;
}

.call_seller {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.seller_icons li {
  border: 2px solid whitesmoke;
  padding: 15px;
  margin-right: 15px;
  border-radius: 8px;
}

.seller_icons li i {
  font-size: 25px;
  color: gray;
}

.seller_icons a {
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 50px;
}

/*----Login--Account-------*/
.account_login form input {
  height: 45px;
  border-radius: 6px;
  outline: 0;
  padding-left: 35px;
  border: 1px solid #ccc;
}

.account_login form .btn {
  border-radius: 10px;
}


.no-car-found-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  /* Top margin of 50px */
  margin-bottom: 50px;
  /* Bottom margin of 50px */
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
}


.more_options a {
  color: gray;
  text-decoration: underline;
}

#root>div.filters.mt-5.search_area.container>div {
  padding-top: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none !important;
}

#root>div.filters.mt-5.search_area.container>div>div>form>div>div.d-flex.justify-content-end>button {
  height: 50px !important;
  border-radius: 12px !important;
}


#root>div.mar_top>section.login.container.d-flex.flex-column.justify-content-center.align-items-center.py-4.py-md-5>div>div>div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#root>div.mar_top>section.login.container.d-flex.flex-column.py-4.py-md-5>div>div>div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid whitesmoke !important;
}

#root>section>div>div>div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#root>div>section.login.container.d-flex.flex-column.justify-content-center.py-4.py-md-5>div>div>div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}



.d-none {
  display: none;
}

.other-filter {
  display: none;
}

.navbar .dropdown-menu .dropdown-item {
  font-size: 14px;
}

button:focus {
  box-shadow: none !important;
}

#filters>div>div>form>div>div.d-flex.justify-content-end>button {
  height: 50px;
  border-radius: 10px !important;
}

/*---------Navbar---------*/
.navbar-toggler {
  border: 0 !important;
  padding: 0 !important;
}

.offcanvas.offcanvas-end {
  width: 300px !important;
}

.account_details .inner_detail {
  display: block !important;
  max-width: 100% !important;
  margin-top: 50px;
  padding: 0 !important
}

.profile_img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 4px solid #089fb6;
}

.settings .profileImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative !important;
}

.settings .profileImg .editbtn {
  position: absolute !important;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 50% !important;
  color: #fff !important;
  background-color: #00889c !important;
  display: flex;
  justify-content: center;
  align-items: center !important;
  font-size: 13px !important;
  cursor: pointer !important;
  transition: 0.5s ease-in !important;
}

.settings .profileImg .editbtn:hover {
  background-color: #fff !important;
  color: #00889c !important;
}

.update_img {
  height: 100% !important;
  width: 100% !important;
  object-fit: fill !important;
  object-position: center !important;
  border-radius: 50%;
}

.offcanvas-end.show {
  /* max-width: 300px !important; */
  border-left: none !important;
}

.offcanvas-body .nav-link {
  margin: 8px;
  border-radius: 8px;
  background-color: #ECFBFB;
  text-align: center;
  border-radius: 8px;
  color: #04798b !important;
  height: 42px;
}

.offcanvas-body .nav-link.active {
  background-color: #00889C;
  color: white !important;
}


.account_login form i {
  position: absolute;
  left: 10px;
  top: 14px;
  color: #bfbdbd;
}

/*----------MyListings--------*/
.btn_add {
  background-color: #00889C;
  color: white;
  padding: 4px;
  border-radius: 4px;
}

/*---------list--details--------*/

.list_details form input,
.list_details form select {
  width: 100%;
  padding-left: 25px;
  border-radius: 8px;
  height: 40px;
  border: 1px solid #ccc;
  outline: 0;
  font-size: 15px;
  color: gray;
}

.list_details textarea {
  border: 1px solid #ccc;
  outline: 0;
  font-size: 15px;
  color: gray;
  width: 100%;
  border-radius: 8px;
  padding-left: 25px;
  padding-top: 10px;
}

.list_details form .row {
  border: 3px solid #fffffffa;
  padding: 20px 10px;
  border-radius: 10px;
}

.list_details form .fa-solid {
  position: absolute;
  left: 7px;
  top: 12px;
  color: #ccc;
}

.list_details form .border {
  border: 1px solid #ccc !important;
  border-radius: 8px;
}

.list_details form a {
  border-radius: 50px;
  padding: 15px 40px;

}

select:focus {
  box-shadow: none !important;
  border: 1px solid #ccc !important;

}

.price {
  color: #089fb6;
}

.list_preview .card {
  border: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.list_preview .card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.list_preview .card i {
  color: #089fb6;
}

.list_preview .description li {
  padding: 15px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #089fb6;
  border-radius: 5px;
  font-size: 14px;

}


/*------Unlock requests-----------*/
.sml-card hr {
  border-top: 3px solid #089fb6;
  margin: .5rem 0 !important;
}


.sml-card .card h5 {
  font-size: 16px;
}

.sml-card .card h6 {
  font-size: 13px;
}

.sml {
  font-size: 12px;
}

/*----ProfileSettings-----------*/
.payment_card {
  height: 230px;
  border-radius: 8px;
}

/*------deleteAccount--------*/

.delete_account li {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px 20px;
  border-left: 4px solid #00889C;
  text-align: justify;
}

.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item:hover {
  background-color: #00889C !important;
  color: white !important;
}

/*-----Contact Seller--modal-----*/

.seller_form .border {
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 45px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.seller_form .border i {
  position: absolute;
  left: 10px;
  color: #ccc;

}

.seller_form input {
  padding-left: 45px;
  border: 0;
  outline: 0;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}

.seller_form select {
  outline: 0;
  border-radius: 8px;
  height: 45px;
  font-size: 14px;
  color: gray;
}

/*-----Buy plan-----*/

.buy_plan .all_brands img {
  width: 50px;
  height: 50px;
}

.buy_plan li h6 {
  text-decoration: underline;
}

.buy_plan li .border {
  font-size: 15px;
}

.buy_plan .form-check-input:focus {
  box-shadow: none !important;
}

.all_pay_cards img {
  width: 50px;
}

.payment_details i {
  font-size: 22px;
  color: #00889C;
}

.payment_details input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #ccc;
  margin-left: 15px;
  padding: 0 15px;
  font-size: 15px;
}

.payment_details input[type="date"] {
  color: gray;
}

.payment_details input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.logout .btn {
  width: 240px;
}

::-webkit-scrollbar {
  display: none;
}


.modal {
  font-family: 'Poppins';
}

.lg-react-element {
  column-count: 3;
  column-gap: 30px;
}

.lg-react-element img {
  display: block;
  max-width: 100%;
  padding: 5px 0px;
}

.border-l {
  border-left: 3px solid #00889C;
}

.myshow {
  display: block !important;
}

.modal .carousel-control-prev i,
.modal .carousel-control-next i {
  font-size: 35px;
  font-weight: 900 !important;
}

/* .about img{
  height: 320px;
} */

.border_red {
  border: 3px solid red;
}

.detail_card1 .swiper-slide .border {
  display: none !important;
}





.bullet_points li {
  list-style-type: disc;
}

.all_faqs .accordion-button:not(.collapsed) {
  background-color: #00889C !important;
  color: white !important;
}

.all_faqs .accordion-body {
  font-size: 15px;
}


/* write by dev */


.bg-primary {
  background-color: #00889c !important;
  color: #fff !important;
}

.text-primary {
  color: #00889c !important;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

body {
  background-color: #f1f1f1 !important;
}

.glassmorphism-1 {
  background: #f1f1f14d !important;
  box-shadow: 0 8px 32px 0 #00879c0d;
  backdrop-filter: blur(3.5px) !important;
  -webkit-backdrop-filter: blur(3.5px) !important;
  border-radius: 20px !important;
  border: 2px solid #fff !important;
}

.add_car_container .input-group .form-control {
  color: #00889c !important;
  border: 2px solid #00889c !important;
  border-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.add_car_container .input-group .form-control:focus {
  box-shadow: none !important;
}

.add_car_container .input-group .form-control::placeholder {
  color: #00889c !important;
  font-weight: 600 !important;
}

.fs-sm {
  font-size: 13px !important;
}

.card_detail_list ul {
  list-style: none !important;
  padding-left: 0px !important;
  padding: 8px !important;
  padding-bottom: 0px !important;
}

.card_detail_list ul li {
  line-height: 2 !important;
  font-size: 1rem !important;
  display: flex !important;
  justify-content: space-between !important;
  font-family: "Poppins", sans-serif;
}

.card_detail_list ul li .list-heading {
  font-weight: 600 !important;
  color: #00889c !important;
}

.decided-btn {
  display: inline-block;
  padding: 8px 36px;
  font-size: 16px;
  font-weight: 500 !important;
  text-align: center;
  text-decoration: none;
  border: 1px solid #00889c;
  color: #fff;
  background-color: #00889c;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.decided-btn:hover {
  background-color: #017587;
  color: #fff;
}

.thank_heading {
  color: #00889c !important;
  font-family: "Poppins", sans-serif;
}

.thank_text {
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  text-align: center !important;
}

.thank_text a {
  text-decoration: none !important;
  color: #00889c !important;
  font-weight: bold;
}

.car_spacification p {
  /* font-size: 12px !important; */
  font-family: "Poppins", sans-serif;
  margin: 0px !important;
}

.car_spacification ul {
  padding-left: 0px !important;
  font-family: "Poppins", sans-serif;
}

.car_spacification ul li {
  line-height: 2.3 !important;
  font-size: 15px !important;

}

.car_spacification ul li i {
  color: #00889c !important;
  font-weight: bold !important;
  margin-right: 8px !important;
}

.car_spacification ul li span {
  color: #9a9999 !important;
  font-size: 14px !important;
}

.primay-btn {
  display: inline-block;
  padding: 8px 36px;
  font-size: 16px;
  font-weight: 500 !important;
  text-align: center;
  text-decoration: none;
  border: 1px solid #00889c;
  color: #fff;
  background-color: #00889c;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.primay-btn:hover {
  background-color: #017587 !important;
  color: #fff;
}

.car_info_strip_color {
  border-left: 4px solid #00889c !important;
  color: #00889c !important;
}

.car_info_strip {
  font-family: "Poppins", sans-serif;
}

.car_info_strip ul {
  list-style: none !important;
  padding: 0px !important;

}

.car_info_strip ul li {
  display: flex;
}

.car_info_strip ul li i {
  margin-right: 8px !important;
}

.more_filter .card {
  border: 1.5px solid #00889C !important;
  border-radius: 9px !important;
}

.more_filter .form-check-input {
  height: 20px !important;
  width: 20px !important;
  border: 1.5px solid #00889C !important;


}

.more_filter .form-check-label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  color: #8E9299 !important;
}

.more_filter .form-check-input:checked {
  background-color: #00889c !important;
}

.more_filter .form-check-input:focus {
  box-shadow: none !important;
}

/* end code by dev */

@media screen and (max-width:1200px) {
  .detail_card p {
    font-size: 14px;
  }
}

@media screen and (max-width:768px) {
  /* .main_banner1 {
    background: none;
  } */

  .main_banner1 .inner_detail {
    margin-top: 50px;
  }

  .main_banner1 .filters {
    margin: 1rem 0 !important;
  }

  .main_banner1 .inner_detail {
    width: 100% !important;
    margin-left: 0;
    left: 0 !important;
  }

  /* .main_banner1 .filters {
    display: none;
  } */

  #new_filters {
    display: block !important;
    margin-top: 1rem !important;
  }

  .login .btn-primary {
    width: 100% !important;
  }

  .card_detail_list ul li {
    line-height: 2 !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: space-between !important;
    font-family: "Poppins", sans-serif;
  }



  .new_page .inner_detail {
    margin-top: 50px !important;
    margin-left: 0 !important;
    position: relative !important;
    width: 100%;
  }

  .new_page .filters {
    margin-top: 1rem !important;
  }

  .main_banner {
    height: 450px;
    display: none;
  }

  .inner_detail {
    margin-top: 50px;
    /* position: fixed !important; */
    top: -1px;
    padding: 0 !important;
    /* width: 100%; */
  }

  .main_banner1 {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .inner_detail .card {
    z-index: 11 !important;
  }

  .filters {
    margin-top: 12rem !important;
  }

  .mar_top {
    margin-top: 9rem !important;
  }

  .inner_detail .card:nth-child(1) {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .inner_detail {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
  }

  #root>div.filters.mt-5.search_area.container>div>div>form>div>div.d-flex.justify-content-end>button {
    width: 100% !important;
  }

  .inner_detail {
    max-width: 100% !important;
    z-index: 11 !important;
  }

  .account_login h5 {
    font-size: 18px;
  }

  .account_login h4 {
    font-size: 18px;
  }

  .conf_reg h4,
  .change_pass h4 {
    font-size: 18px;
  }

  .about,
  .how_use p {
    font-size: 15px;
  }

  footer p,
  .login p {
    font-size: 14px;
  }

  .detail_card p,
  .call_seller p {
    font-size: 12px;
  }

  .policies li a {
    font-size: 14px;
  }

  .detail_card h6 {
    font-size: 14px;
  }

  .detail_card h4,
  .detail_card h5 {
    font-size: 17px;
  }

  #filters>div>div>form>div>div.d-flex.justify-content-end>button {
    width: 100% !important;
  }

  .sml-card .btn-danger,
  .sml-card .btn-primary {
    font-size: 15px;
  }

  .about h5 {
    font-size: 1rem;
  }

  .how_use h5 {
    font-size: 2rem;
  }

  .how_use i {
    font-size: 25px;
  }

  .list_search_lg {
    display: none;
  }

  .list_search_sml {
    display: block !important;
  }
}

@media screen and (max-width:475px) {
  .contact_modal h5 {
    font-size: 1rem;
  }

  .small_modal h5 {
    font-size: 1rem;
  }

  .small_modal h4 {
    font-size: 1.2rem;
  }

  .other_media span::before {
    width: 70px;
    left: -75px;

  }

  .other_media span::after {
    width: 70px;
    right: -75px;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .main_banner {
    height: 350px;
  }

  .adv_search .btn {
    padding: 2px 15px;
    font-size: 13px;
  }

  .more_options a {
    font-size: 12px;
  }

  .all_media_icons li i {
    font-size: 20px;
    background-color: white;
    padding: 6px 8px;
    border-radius: 50%;
  }

  .account_login h5 {
    font-size: 16px;
  }

  footer p {
    font-size: 16px;
  }

  #more_filters {
    font-size: 13px;
  }


  .login h4 {
    font-size: 17px;
  }

  .login .btn-primary {
    font-size: 14px;
    height: 45px;
  }

  h2 {
    font-size: 20px;
  }

  .select_btn li {
    font-size: 13px;
  }

  .policies li a {
    font-size: 12px;
  }

  h3 {
    font-size: 20px;
  }

  .profile_update .card li span {
    font-size: 14px;
  }

  .payment_card {
    height: 180px;
  }

  .profile_update .card h6 {
    font-size: 12px;
  }

  footer h2 {
    font-size: 36px !important;
  }

  .delete_account li {
    font-size: 12px;
    padding: 10px 5px;
  }

  .delete_account h4 {
    font-size: 19px;
  }

  .delete_account .btn {
    font-size: 12px;
  }

  .list_preview .description li {
    font-size: 12px;
    padding: 10px 10px;
  }

  .list_preview .description li p {
    font-size: 11px !important;
  }

  .list_preview .description ul {
    padding: 0 0 !important;
  }

  .list_preview p {
    font-size: 14px;
  }

  .buy_plan li .border {
    font-size: 15px;
  }

  .buy_plan .all_brands img {
    width: 35px;
    height: 35px;
  }

  .payment_details input {
    font-size: 13px;
  }

  .payment_details i {
    font-size: 18px;
  }

  .payment_details .btn-outline-primary {
    font-size: 14px;
  }

  .about h5 {
    font-size: 1rem;
  }

  .how_use h5 {
    font-size: 1rem;
  }

  .how_use i {
    font-size: 25px;
  }

  .all_pay_cards img {
    width: 35px;
  }

  .slides {
    height: 300px;
  }

  /* .about img{
    height: 300px;
  } */
  .list_search_sml h3 {
    font-size: 17px;
  }

  .list_search_sml h6 {
    font-size: 15px;
  }

  .decided-btn {
    padding: 8px 16px !important;
  }

  .more_filter .form-check-input {
    height: 20px !important;
    width: 20px !important;
  }

  .more_filter .form-check-label {
    font-size: 12px !important;

  }
}

@media screen and (max-width:375px) {
  .contact_modal h5 {
    font-size: .9rem;
  }

  .small_modal h5 {
    font-size: .9rem;
  }

  .other_media span::before {
    width: 35px;
    left: -40px;
  }

  .other_media span::after {
    width: 35px;
    right: -40px;
  }

  .login img {
    height: 50px;
    padding: 10px 15px;
  }

  .other_media span {
    font-size: 14px;
  }

  .navbar-brand img {
    width: 40px !important;
  }

  .navbar-brand h3 {
    font-size: 18px;
  }

  .dropdown-toggle {
    font-size: 11px;
  }

  .dropdown-menu[data-bs-popper] {
    left: -80px !important;
  }

  .search_area input,
  .search_area select {
    font-size: 11px;
  }

  .search_area .border input {
    font-size: 12px !important;
    height: 35px;
  }

  .search_area .border i {
    top: 14px;
  }

  .search_area button,
  .search_area .btn-light,
  .search_area .btn-primary {
    font-size: 13px !important;
  }

  nav .dropdown-toggle i {
    background-color: black;
    color: white;
    font-size: 10px;
    padding: 5px 6px;
    border-radius: 50%;
  }

  .navbar .dropdown-menu .dropdown-item {
    font-size: 12px;
  }

  footer p,
  .login p {
    font-size: 10px;
  }

  .policies li a {
    font-size: 8px;
  }

  .navbar-toggler-icon {
    height: 1em !important;
    width: 1em !important;
  }

  .account_login form input {
    font-size: 13px;
  }

  .account_login form a {
    font-size: 13px;
  }

  .account_login form .btn-primary,
  .account_login form .btn-outline-primary {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .list_details p {
    font-size: 15px;
  }

  .list_details form a {
    font-size: 14px;
    padding: 10px 34px;
  }

  .profile_update .card li span {
    font-size: 12px;
  }

  .settings .profileImg {
    height: 5rem !important;
    width: 5rem !important;
  }

  .update_img {
    height: 100%;
    width: 100%;
  }

  .profile_update h4 {
    font-size: 19px;
  }

  .profile_update .btn-sm {
    font-size: 10px
  }

  footer .brand img {
    width: 60px;
  }

  .logout h4 {
    font-size: 19px;
  }

  .logout .btn {
    width: 220px !important;
    font-size: 14px;
  }

  .purchase_credit h4 {
    font-size: 19px;
  }

  .purchase_credit .btn {
    font-size: 14px;
  }

  .how_use h5 {
    font-size: 1rem;
  }

  .slides {
    height: 250px;
  }

  .list_search_sml .btn-primary {
    font-size: 11px;
  }

}

.atm-card {
  width: 500px;
  height: 250px;
  background-color: #000000;
  border-radius: 15px;
  padding: 20px;
  color: white;
  position: static;
  align-content: center;
}

.card-logo img {

  width: 80px;
  margin-left: 100px;
  /* Adjust width as needed */
}

.chip {
  width: 50px;
  height: 35px;
  background-size: cover;
  position: absolute;
  top: 20px;
  right: 20px;
}

.card-number {
  font-size: 20px;
  margin-bottom: 20px;
  width: 70px;
}

.card-number-1 {
  font-size: 20px;
  margin-bottom: 20px;
  width: 230px;
}

.card-info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.expiry-date,
.cvv {
  font-size: 14px;
}

.navbar-brand.d-flex.align-items-center.me-4 {
  justify-content: center;
}

.card-number:focus-visible {
  border: none !important;
  color: black;
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
}

.card-custom-focus:focus-visible {
  border: none !important;
  color: white;
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px;
}

.card-number::placeholder {
  color: grey;
  /* Placeholder color */
}

.ard-custom-focus::placeholder {
  color: grey;
  /* Placeholder color */
}

.card-make {
  border-top-left-radius: 15px !important;
  background: rgb(0, 148, 215);
  border-top-right-radius: 15px;
}

.head-text {
  color: #fff !important;
}

.form-control {
  height: 45px !important;
  border-width: 1.4px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #00889C;

}

.invalid {
  border-color: red !important;
}

.form-check-input:checked {
  background-color: #00889C !important;
}

.form-check-input {
  appearance: unset !important;
  border: 1px solid #00889C !important;
  border-radius: 5px !important;
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url('./check.svg');
}

.bullet_points li {
  margin-bottom: 10px !important;
  display: flex !important;
  align-items: center !important;
}

.bullet_points {
  padding-left: 0px !important;
}

.bullet_points li::marker {
  color: #00889C !important;
  font-size: 2rem !important;
}

.bullet_points li h5 {
  margin: 0px !important;
}

.bullet_points img {
  height: 30px !important;
  width: 30px !important;
  margin-right: 10px !important;
}

.custom-ui {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(31, 128, 135, 0.37);
  backdrop-filter: blur(10px);

  -webkit-backdrop-filter: blur(10px);
  padding: 30px;
  text-align: center;
}

.custom-ui h1 {
  color: #222222;
  font-size: 20px;
  margin-top: 0;
  font-weight: 700 !important;
}

.custom-ui p {
  color: #666;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500 !important;
}



@media (max-width: 600px) {
  .custom-ui {
    max-width: 90%;
    padding: 15px;
  }

  .custom-ui h1 {
    font-size: 15px;
  }

  .custom-ui p {
    font-size: 13px;
  }

}

.form-check-label {
  font-size: 14px !important;
}

.grid_img div img {
  border-radius: 0 !important;
  object-fit: fill !important;
  object-position: center !important;
  border-bottom: 2px solid #00889C !important;
  border-left: 2px solid #00889C !important;
}

img {
  object-position: center !important;
  object-fit: fill !important;

}

.card_filter_card {
  border-radius: 8px !important;
}

.grid_img div:nth-child(1) img {
  /* border-top-right-radius: 5px !important; */
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.card_filter_card .image-container {
  border: 1.5px solid #00889C !important;
  /* height: 180px !important; */
  border-radius: 8px !important;


  overflow: hidden;
}
.card_filter_card .image-container img{
  object-fit: contain !important;
  object-position: top !important;
}
.card_filter_card .image-container .grid_img img{
  object-fit: fill !important;
  object-position: center !important;
}
.card_filter_card h5 {
  word-wrap: break-word !important;
  font-size: 14px !important;
}

.card_filter_card {
  height: 100% !important;
  /* max-width: 26rem !important; */
}

.inputgroup input:focus {
  border-left: 0;
}

.input-group-text {
  background-color: #00889c !important;
  border: 0;
  color: white !important;
}

.fs-7 {
  font-size: .8rem !important;
  font-weight: 600;
}

.search_area input,
.search_area select {
  border: 1.4px solid #00889c;
  color: #00889c;

  opacity: 1;
}

.search_area input:focus,
.search_area select:focus {
  border: 1.4px solid #00889c !important;
  color: #00889c;
  opacity: 1;
}

.smallfilter input:focus,
.smallfilter select:focus {
  border: 1.4px solid #00889c !important;
  color: #00889c;
  opacity: 1;
}

.smallfilter input,
.smallfilter select {
  border: 1.4px solid #00889c !important;
  color: #00889c;
  opacity: 1;
}

.styled-hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 20px 0;
  width: 100%;
}

.form-select {
  padding: 8px !important;
  font-size: 14px !important;
}

.hr {
  border-top: 1px solid #00889c !important;
  opacity: 1 !important;
}

.car_info h6 {
  font-size: 12px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #f8f9fa !important;
  font-size: 1rem !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.5rem !important;
}

hr {
  margin: 8px 0px !important;
}

.mainbanner {
  background: url(../public/img/bg_2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding-top: 100px; */
  padding-bottom: 50px;
  width: 100% !important;
  background-attachment: fixed;
}

.carinfo li {
  padding: 10px;
}

.car_upload_disc li {
  display: flex !important;
  align-items: center !important;
}
.car_upload_disc li span{
  margin-right: 6px !important;
  font-size: 24px;
  display: flex;
  align-items: start;
}
.ql-editor{
  min-height: 200px;
}
.carDescription ul li{
  list-style: unset !important;
}





/* new added after client share this  */
.list_details form .form-control, 
.list_details form select, 
.list_details textarea {
    border: 1px solid #00889c !important;
    color: #222222 !important; 
    font-size: 16px !important;
    outline: 0 !important; 
    padding: 10px 25px 10px 25px !important; 
    height: 49px;
}
.quill,.list_details form .border{
  border: 1px solid #00889c !important;
}
.list_details form .fa-solid{
  top: 17px !important;
}



/* Default styling for rich text editor content */
.moreTextshow {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  line-height: 1.6 !important;
  color: #333 !important;
  margin: 16px 0 !important;
}

/* Headings */
.moreTextshow h1, 
.moreTextshow h2, 
.moreTextshow h3, 
.moreTextshow h4, 
.moreTextshow h5, 
.moreTextshow h6 {
  margin: 16px 0 !important;
  font-weight: bold !important;
  color: #222 !important;
  font-size: 1rem !important;
}

/* Paragraphs */
.moreTextshow p {
  margin: 12px 0 !important;
}

/* Links */
.moreTextshow a {
  color: #00889c !important;
  text-decoration: none !important;
}

.moreTextshow a:hover {
  text-decoration: underline !important;
}

/* Lists */
.moreTextshow ul {
  margin: 0px !important;
  padding-left: 20px !important;
}

.moreTextshow ol {
  margin: 0px !important;
  padding-left: 20px !important;
  list-style:decimal !important;
}

.moreTextshow li {
  margin-bottom: 2px !important;
  list-style:decimal !important;
}

/* Images */
.moreTextshow img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 12px auto;
}

/* Blockquotes */
.moreTextshow blockquote {
  margin: 16px 0;
  padding-left: 20px;
  border-left: 4px solid #ccc;
  color: #666;
  font-style: italic;
}

/* Code Blocks */
.moreTextshow pre {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

.moreTextshow code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

/* Tables */
.moreTextshow table {
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
}

.moreTextshow th, 
.moreTextshow td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.moreTextshow th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Horizontal Rule */
.moreTextshow hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 16px 0;
}

.inputcheck{
  padding:0px !important;
}


















/* Container styling */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Toggle button styling */
.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  padding: 10px 5px !important;
  border-radius: 10px;
  background-color: #00889C;
  border: 1px solid #00889C;
  color: #fff !important;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.toggle-btn::before {
  content: '';
  position: absolute;
  height: 28px;
  width: 68px;
  border-radius: 8px;
  background-color: #fff; 
  color: #00889C;
  transition: transform 0.3s ease;
  transform: translateX(0);
  z-index: 1;
}

.toggle-btn.yearly::before {
  transform: translateX(100%);
}

.toggle-text {
  position: relative;
  z-index: 2;
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #fff;
  transition: color 0.3s ease;
}

.toggle-text.active {
  color: #00889C;
  font-weight: bold;
}
.plancard{
  border: 2px solid #089fb6 !important;
  color:#222222 !important;
  border-radius: 20px !important;
}
.plancard .subtext{
   font-size: 14px !important;
}
.plancard .month{
   font-size: 12px !important;
}
.plancard.active{
  background-color: #00889C !important;
  color: #fff !important;

}